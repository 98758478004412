@use 'style';

:root {
  --font-family-base: Montserrat, sans-serif;
  --font-family-heading: Montserrat, sans-serif;
  --font-family-monospace: Fira Mono, monospace;
  --font-family-logo: Montserrat, sans-serif;

  --color-primary-bg: #0000ff;
  --color-primary-bg-2: #000000;
  --color-primary-bg-3: #0d042b;
  --color-primary-text: #ff0000;
  --color-base-bg: #ffffff;
  --color-base-bg-2: #EDC92B;
  --color-base-bg-3: #00ff00;
  --color-base-text: #262626;
  --color-base-text-2: #000000;
  --color-header-text:  #262626;

  --logo-text-font-size: 28px;
  --logo-text-font-weight: 500;

  --menu-font-family: Montserrat, sans-serif;
  --menu-font-size: ;
  --menu-text-transform: "Capitalize";
  --menu-font-weight: 400;

  --header-height: 60px;
  --section-padding: 80px;
  --card-border-radius: 5px;
  --button-border-radius: 5px;
}

html[data-bs-theme='dark'] {
  --color-primary-bg: #fdfcf9;
  --color-primary-bg-2: #fdfcf9;
  --color-primary-bg-3: #fdfcf9;
  --color-primary-text: #0b0326;
  --color-base-bg: #0b0326;
  --color-base-bg-2: #150c32;
  --color-base-bg-3: #251654;
  --color-base-text: #f5f5ec;
  --color-base-text-2: #929eaf;
  --color-logo-text: #fdfcf9;
  --color-header-text: #fdfcf9;
}

/* Carousel base class */
.carousel {
  margin-bottom: 1rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 50rem;
}

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }
}
