// Lightmode colors
$primary: #000062;
$primary-2: #000000;
$primary-text: #ffffff;
$secondary:  #000000;
$base-bg: #ffffff;
$base-bg-2: #ffffff;
$base-bg-3: #ffffff;
$base-text: #000062;
$base-text-2: #000000;
$logo-text:  #000000;
$menu-text:  #000000;

// Darkmode colors
$primary-dark: #fdfcf9;
$primary-2-dark: #fdfcf9;
$primary-text-dark: #f5f5ec;
$secondary-dark:  #251654;
$base-bg-dark: #0b0326;
$base-bg-2-dark: #150c32;
$base-bg-3-dark: #251654;
$base-text-dark: #f5f5ec;
$base-text-2-dark: #929eaf;
$logo-text-dark: #fdfcf9;
$menu-text-dark:  #F4F4F5;

$zs-colors-light: (
    "primary": $primary,
    "primary-2": $primary-2,
    "primary-text": $primary-text,
    "secondary": $secondary,
    "base-bg": $base-bg,
    "base-bg-2": $base-bg-2,
    "base-bg-3": $base-bg-3,
    "base-text": $base-text,
    "base-text-2": $base-text-2,
    "logo-text": $logo-text,
    "menu-text": $menu-text
);

$zs-colors-dark: (
    "primary": $primary-dark,
    "primary-2": $primary-2-dark,
    "primary-text": $primary-text-dark,
    "secondary-dark": $secondary-dark,
    "base-bg": $base-bg-dark,
    "base-bg-2": $base-bg-2-dark,
    "base-bg-3": $base-bg-3-dark,
    "base-text": $base-text-dark,
    "base-text-2": $base-text-2-dark,
    "logo-text": $logo-text-dark,
    "menu-text": $menu-text-dark
);

$zs-colors-light-css-vars: ();
@each $color, $value in $zs-colors-light {
  $zs-colors-light-css-vars: map-merge(
    $zs-colors-light-css-vars,
    (#{$color}: var(--color-#{$color}))
  )
}

$font-family-heading: Barlow, -apple-system, serif;
$font-family-base: Barlow, -apple-system, "Helvetica Neue", Arial, sans-serif; 
$font-family-monospace: Fira Mono, SFMono-Regular, Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-logo: Barlow, -apple-system, "Helvetica Neue", Arial, sans-serif;

:root{  
  @each $color, $value in $zs-colors-light {
    --color-#{$color}: #{$value};
  }
}

html[data-mode='dark'] {
  @each $color, $value in $zs-colors-dark {
      --color-#{$color}: #{$value};
  }
}

@import 'style';
