.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 10px 0 10px 0;
  padding: 0;
  ul {
    display: flex;
    li {
      list-style: none;
      text-decoration: none;
      border: 1px solid var(--color-base-text);
      color: var(--color-base-text);
      border-right: none;
      text-align: center;
      vertical-align: middle;
      a,
      span {
        padding: 8px 12px;
        display: block;
        color: var(--color-base-text);
        text-decoration: none;
        &:hover {
          background-color: var(--color-base-text);
          color: var(--color-base-bg);
        }
      }
      &.active {
        a,
        span {
          background-color: var(--color-base-text);
          color: var(--color-base-bg);
        }
      }
      &:last-of-type {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-right: 1px solid var(--color-base-text);
      }
      &:first-of-type {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }
}
.pagination-count {
  margin-bottom: 20px;
  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -0.2px;
    margin-bottom: 6px;
  }
}
